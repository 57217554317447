body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.content4-wrapper {
  min-height: 720px;
  background: #fafafa;
}
.content4-wrapper .content4 {
  height: 100%;
  overflow: hidden;
}
.content4-wrapper .content4-video {
  border-radius: 4px;
  overflow: hidden;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.content4-wrapper .content4-video video {
  display: block;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .content4-wrapper {
    min-height: 350px;
  }
  .content4-wrapper .content4 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content4-wrapper .content4-video {
    top: 15%;
    background: url("https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg") no-repeat center;
    background-size: cover;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 48px;
  line-height: 1.5;
  color: #5f9bf1;
  margin-bottom: 8px;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 24px;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  color: #666;
  margin-bottom: 48px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: absolute;
  top: 15%;
  right: 24px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.content3-wrapper {
  min-height: 764px;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    min-height: 1080px;
  }
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.feature7-wrapper {
  min-height: 564px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #f7f9fc;
}
.feature7-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature7-title-wrapper {
  text-align: center;
  margin-bottom: 40px;
}
.feature7-title-h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
}
.feature7-title-content {
  margin-top: 16px;
}
.feature7-block {
  margin-top: 28px;
}
.feature7-block-group {
  display: block;
  padding: 28px 24px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  background-image: url('https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*fMOFSpRXMxsAAAAAAAAAAABkARQnAQ');
  background-repeat: no-repeat;
  background-position: 100% 100%;
  transition: box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature7-block-group:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}
.feature7-block-image {
  float: left;
  width: 24px;
}
.feature7-block-title {
  font-size: 14px;
  float: left;
  margin-left: 8px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
}
.feature7-block-content {
  clear: both;
  color: rgba(0, 0, 0, 0.75);
}
@media screen and (max-width: 767px) {
  .feature7-wrapper {
    min-height: 1540px;
  }
  .feature7-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px;
  }
}
.content13-wrapper {
  min-height: 380px;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.content13-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content13-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content13-wrapper {
    padding-bottom: 0;
  }
}
.content10-wrapper {
  height: 480px;
  background: url(https://gw.alipayobjects.com/os/s/prod/seeconf/c66ebea962cdf544926ca5a472dea5ea.jpg) no-repeat 50%;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content10-wrapper .icon-wrapper {
  text-align: center;
  position: relative;
  cursor: pointer;
}
.content10-wrapper .icon-wrapper img {
  display: block;
}
.content10-wrapper .icon {
  position: relative;
  z-index: 1;
  -webkit-animation: BeatAnim 2s ease-in-out infinite;
          animation: BeatAnim 2s ease-in-out infinite;
}
.content10-wrapper .icon-shadow {
  display: inline-block;
  position: relative;
  top: -12px;
  z-index: 0;
  -webkit-animation: ScaleAnim 2s ease-in-out infinite;
          animation: ScaleAnim 2s ease-in-out infinite;
  -webkit-transform-origin: 50%;
          transform-origin: 50%;
}
.content10-wrapper .map-tip {
  position: absolute;
  width: 330px;
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(13, 26, 38, 0.12);
  left: 50%;
  top: 50%;
  margin-left: 30px;
  margin-top: -60px;
  font-size: 14px;
  z-index: 10;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
  text-align: left;
}
.content10-wrapper .map-tip h2 {
  font-size: 16px;
  color: #0d1a26;
  margin-bottom: 8px;
}
@media screen and (max-width: 767px) {
  .content10-wrapper {
    padding-bottom: 0;
  }
}
@-webkit-keyframes BeatAnim {
  0%,
  25%,
  35%,
  45% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  15% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  30% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
  40% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
  }
}
@keyframes BeatAnim {
  0%,
  25%,
  35%,
  45% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  15% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  30% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
  40% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
  }
}
@-webkit-keyframes ScaleAnim {
  0%,
  25%,
  35%,
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  15% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  30% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  40% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@keyframes ScaleAnim {
  0%,
  25%,
  35%,
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  15% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  30% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  40% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
#Feature3_0.lffoktl8kz9-editor_css {
  min-height: 100vh;
}
#Feature7_0.lffokpmtm0m-editor_css {
  display: flex;
  height: auto;
  min-height: 100vh;
}
#Banner5_1 .lffql6nnwdc-editor_css {
  max-width: 1400px;
}
#Banner5_1 .home-page > .lffpyyri8dj-editor_css {
  max-width: 751px;
}
#Banner5_1 .banner5-title-wrapper > .banner5-button-wrapper > .lffpz1duawo-editor_css {
  display: none;
}
#Banner5_1 .home-page > .lffqkzdp44e-editor_css {
  max-width: 100%;
  left: 0px;
  padding: 0 20px;
}
#Contact0_1.lffn8b5wo5t-editor_css {
  height: calc(100vh - 50vh - 80px);
  background-image: url('https://coldchain.uzaiuzhai.com/coldchainimage/ditu.jpg');
}
#Feature7_0 .lffop63l15g-editor_css {
  min-height: 100vh;
  padding: 128px 24px;
}
#Content13_0 .title-wrapper > .lffu72vkpcj-editor_css {
  font-size: 20px;
  line-height: 3;
}
#Content13_0 .title-wrapper > .lffuc4gol2r-editor_css {
  font-size: 18px;
  line-height: 2.5;
}
#Content4_1.lffw6kyewr9-editor_css {
  min-height: 100vh;
}
#Content4_1 .home-page > .title-wrapper > .lffw6sfpnb-editor_css {
  font-size: 16px;
  line-height: 1.8715;
}
#Banner0_1 .banner0-text-wrapper > .lfgcw2giztd-editor_css {
  display: none;
}
#Banner0_1 .banner0-text-wrapper > .lfgcwb1vvl-editor_css {
  display: none;
}
#Banner0_1 .lfgcvzensj-editor_css {
  top: 30%;
}
#Banner0_1 .banner0-text-wrapper > .lfgcvwsyr6n-editor_css {
  width: 270px;
  max-width: 40vw;
  left: 0px;
}
#Content13_0 .title-wrapper > .lfggdw8y6o-editor_css {
  font-size: 18px;
}
#Content13_0 .title-wrapper > .lffu7g9fchb-editor_css {
  font-size: 18px;
  line-height: 2;
}
#Content13_0 .title-wrapper > .lffu6ydqb4-editor_css {
  margin: 10px 0px 16px;
}
#Content13_0.lffu6wxwu6-editor_css {
  background-clip: ;
  height: 50vh;
  background-image: url('');
  padding: 66px 0px 96px;
}
#Feature7_0 .ant-col > .feature7-block-group > .lfiiyej2lh-editor_css {
  display: flex;
  align-items: center;
  height: 22px;
}
#Feature7_0 .ant-col > .feature7-block-group > .lfij0fdaczn-editor_css {
  display: flex;
  align-items: center;
  height: 22px;
}
#Feature7_0 .ant-col > .feature7-block-group > .lfij1qmfgc-editor_css {
  display: flex;
  align-items: center;
  height: 22px;
}
#Feature7_0 .ant-col > .feature7-block-group > .lfij33cowuf-editor_css {
  display: flex;
  align-items: center;
  height: 22px;
}
#Feature7_0 .ant-col > .feature7-block-group > .lfij3gutw6o-editor_css {
  display: flex;
  align-items: center;
}
#Feature7_0 .ant-col > .feature7-block-group > .lfij3y3ps9k-editor_css {
  display: flex;
  align-items: center;
}
#Feature7_0 .ant-col > .feature7-block-group > .lfij41vudja-editor_css {
  display: flex;
  align-items: center;
}
#Feature7_0 .ant-col > .feature7-block-group > .lfij47dt1w-editor_css {
  display: flex;
  align-items: center;
}
#Feature7_0 .ant-col > .feature7-block-group > .lfij4c2hh-editor_css {
  display: flex;
  align-items: center;
}
#Feature7_0 .ant-col > .feature7-block-group > .lfij4fuxem-editor_css {
  display: flex;
  align-items: center;
}
#Feature7_0 .ant-col > .feature7-block-group > .lfij4ju7oh7-editor_css {
  display: flex;
  align-items: center;
}
#Feature7_0 .ant-col > .feature7-block-group > .lfij4poysi-editor_css {
  display: flex;
  align-items: center;
}

